@import '../../../client/ui/base/variables';
@import '../../../client/imperial-overrides';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.checkbox-container {
    position: absolute;
    left: 1rem;
    top: calc(50% - 16px);
}

.checkbox-container.top {
    top: 11px;
}

.checkbox-container.default {
  position: inherit;
}

@include media-breakpoint-down(md) {
    .isSmall {
        display: none!important;
    }
    .isMedium {
        display: block!important;
    }
    .isLarge {
        display: none!important;
    }
    .hideLarge {
        display: none!important;
    }
}

@include media-breakpoint-down(sm) {
    .isSmall {
        display: block!important;
    }
    .isMedium {
        display: none!important;
    }
    .isLarge {
        display: none!important;
    }
    .hideLarge {
        display: none!important;
    }
    .isFeatured {
        display: none!important;
    }
}

@include media-breakpoint-up(lg) {
    .isSmall {
        display: none!important;
    }
    .isMedium {
        display: none!important;
    }
    .isLarge {
        display: block!important;
    }
    .isFeatured {
        display: block!important;
    }
    .hideLarge {
        display: none!important;
    }
}
